import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import DictationComponent from "./DictationComponent";
import ResponseBodyView from './ResponseBodyView';

const SampleNoteAdder = () => {
    const [xUser, setXUser] = useState("");
    const [qid, setQid] = useState("");
    const [sampleId, setSampleId] = useState("");
    const [noteBody, setNoteBody] = useState("");
    const [respBody, setRespBody] = useState({});

    // find sample ID on QID update
    useEffect(() => {
        // fix this eventually
        axios.get(`https://firecracker.qa.mi.cld.3m.com/api/v1/samples?filter=[{"name":"qid","op":"eq","val":"${qid}"}]`, { headers: { "X-User": xUser } }).then(resp => {
            const jsonBody = resp.data.data;
            if (jsonBody.length === 1) {
                setSampleId(jsonBody[0].id);
                setRespBody(jsonBody);
            }
            else {
                console.log(jsonBody.length);
            }
        });
    }, [qid, xUser]);

    function sendNotePatch() {
        console.log("SENDING");
        // hehe we aren't actually sending... 
    }

    return (<form noValidate authoComplete="off">
        <DictationComponent header={{ "X-User": xUser }} noteSetter={setNoteBody} qidSetter={setQid} />
        <Button color="primary" onClick={() => { sendNotePatch() }}>Update Note</Button>
        {respBody ? <ResponseBodyView body={respBody} /> : null}
    </form>);

}

export default SampleNoteAdder;

