import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { HotTable } from "@handsontable/react";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import ResponseBodyView from './ResponseBodyView';
import MuiDataTable from './MuiDataTable';

const data = [
    ['Sample', 'amount'],
    ['titanium monoxide', 10],
    ['titanium dioxide', 20],
    ['titanium trioxide', 30],
    ['epoxy', 40],
    ['epon 828', 50],
    ['d400', 60],
    ['mixing time', 70],
    ['mixing speed', 80],
];

const DictationComponent = ({ header, ...props }) => {
    const [listening, setListening] = useState(false);
    const [NLPBody, setNLPBody] = useState({});
    const [userText, setUserText] = useState('');
    const [hotData, setHotData] = useState(data);


    const commands = [
        {
            command: 'finish',
            callback: () => parseTranscript()
        },
        {
            command: 'clear',
            callback: () => resetTranscript()
        },
    ]
    const { transcript, resetTranscript } = useSpeechRecognition({ commands });

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null
    }

    function parseTranscript(text) {
        const url = 'https://aichemy.rd.mi.cld.3m.com/api/v1/nlp/extract'
        let data = ''
        if (!text) {
            data = { 'input_str': transcript }
        }
        else {
            data = { 'input_str': text }
        }
        const config = { headers: header }
        axios.post(url, data, config).then(resp => {
            const jsonBody = resp.data;
            setNLPBody(jsonBody)
            console.log('API')
            setNewValues(jsonBody['attribute_data'])
        });


    }

    const setNewValues = (attribute_data) => {
        let newHotData = hotData

        Object.keys(attribute_data).forEach(key => {
            const match = attribute_data[key]
            const matchPair = match['1']
            const sampleName = Object.keys(matchPair)[0]
            const sampleAmt = matchPair[sampleName]
            newHotData = newHotData.map((row) => {
                if (row.includes(sampleName)) {
                    return [sampleName, sampleAmt]
                }
                else return row
            })

        })
        setHotData(newHotData)
    }

    function startListening() {
        console.log("Starting listening");
        SpeechRecognition.startListening({ continuous: true });
        setListening(!listening);
    }

    function stopListening() {
        console.log("Stopped Listening");
        SpeechRecognition.stopListening();
        setListening(!listening);
    }
    return (
        <Box>
            {listening ? <Button variant="contained" color="primary" onClick={() => { stopListening() }}>Stop</Button> : <Button variant="contained" color="primary" onClick={() => { startListening() }}>Start</Button>}
            <Button onClick={() => { resetTranscript() }} variant="contained" color="secondary" startIcon={<DeleteIcon />}>Reset</Button>
            <Typography variant="subtitle1">
                Transcript
            </Typography>
            <Typography>{transcript}</Typography>

            <Button onClick={() => parseTranscript(userText)} variant="contained" color="secondary" startIcon={<DeleteIcon />}>Parse Current Text</Button>
            <MuiDataTable data={hotData} />
            {NLPBody ? <ResponseBodyView body={NLPBody} /> : null}
        </Box>
    )
}

export default DictationComponent;