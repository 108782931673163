import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import DictationComponent from './DictationComponent';
import ResponseBodyView from './ResponseBodyView';

const SampleSetNoteAdder = ({ sampleSetId, header, fcHost, ...props }) => {
    const [sampleSetRespBody, setSampleSetRespBody] = useState({});
    const [sampleIds, setSampleIds] = useState([]);
    // find sample ID on QID update
    useEffect(() => {
        // fix this eventually
        axios.get(`${fcHost}/sample_sets/${sampleSetId}`, { headers: header }).then(resp => {
            const jsonBody = resp.data.data;
            if (jsonBody) {
                console.log(jsonBody);
                setSampleSetRespBody(jsonBody);
                if (jsonBody.relationships.samples.data) {
                    setSampleIds(jsonBody.relationships.samples.data.map(elem => elem.id)); // set sampleIds for a given SampleSet
                }
            }
            else {
                console.log(jsonBody);
            }
        });
    }, [sampleSetId, header]);

    return (<form noValidate authoComplete="off">
        <label>
            Sample Set Information:
            <ResponseBodyView body={sampleSetRespBody} />
        </label>
        <label>
            Samples Associated:
            <SampleTable sampleIds={sampleIds} fcHost={fcHost} header={header} />
        </label>

        <DictationComponent header={header} />
    </form>);

}

export default SampleSetNoteAdder;



const SampleTable = ({ sampleIds, fcHost, header, ...props }) => {
    const rows = sampleIds.map(id => <SampleRow sampleId={id} fcHost={fcHost} header={header} />);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>QID</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Alternative Names</TableCell>
                        <TableCell>Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const SampleRow = ({ sampleId, fcHost, header, ...props }) => {
    const [sampleJson, setSampleJson] = useState({});

    useEffect(() => {
        axios.get(`${fcHost}/samples/${sampleId}`, { headers: header }).then(resp => {
            const jsonBody = resp.data.data.attributes;
            if (jsonBody) {
                console.log(jsonBody);
                setSampleJson(jsonBody);
            }
            else {
                console.log(jsonBody);
            }
        });
    }, [sampleId, header])

    if (sampleJson) {
        return (
            <TableRow>
                <TableCell>{sampleJson.qid ? sampleJson.qid : ""}</TableCell>
                <TableCell>{sampleJson.title ? sampleJson.title : ""}</TableCell>
                <TableCell>{sampleJson.alternate_names ? sampleJson.alternate_names : ""}</TableCell>
                <TableCell>{sampleJson.meta ? sampleJson.meta["notes"] : ""}</TableCell>
            </TableRow>
        )
    }
    return null;
}