import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './media/logo.png'
import gradient from './media/gradient_bar.9f3f0614.svg'
import MMM from './media/3M_logo.6cbbbb91.jpg'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styles from './styles/dashboard_styles';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import HelpButton from './components/HelpButton';
import SampleSetNoteAdder from './components/SampleSetNoteAdder.js';

const useStyles = makeStyles(styles);

const SampleSetApp = ({ sampleSetId, ...props }) => {
    const classes = useStyles();
    const fcHost = "https://firecracker.qa.mi.cld.3m.com/api/v1";
    const [header, setHeader] = useState({ "X-User": "", 'Content-Type': 'text/plain' });

    useEffect(() => {
        console.log(header);
    }, [header])

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={logo} height="25" alt="LabCam" />
                    <img src={MMM} height="25" alt="logo" style={{ margin: "auto" }} />
                    <HelpButton />
                </Toolbar>
                <img src={gradient} height="5" width="100%" alt="gradient" />
            </AppBar>
            <textarea value={header["X-User"]} onChange={event => (setHeader({ ...header, "X-User": event.target.value }))} />
            <Paper className={classes.paper} >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SampleSetNoteAdder sampleSetId={sampleSetId} header={header} fcHost={fcHost} />
                    </Grid>

                </Grid>
            </Paper>
        </div>
    );
}
export default SampleSetApp
