import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router } from "@reach/router";
import { decode } from 'url-encode-decode';
import SampleSetApp from './SampleSetApp';

const TopLevel = ({ sampleSetId, ...props }) => {
  //const headers = new Headers();
  console.log("In top Level");
  //headers.append("X-User", jwt)
  //return <SampleSetApp header={headers} sampleSetId={sampleSetId} />;
  return <SampleSetApp sampleSetId={sampleSetId} />;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <TopLevel path="/:sampleSetId" />
      <App path="/" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);