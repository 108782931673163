import { TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Box, Grid } from "@material-ui/core";
import React from 'react';

const MuiDataTable = ({ data, ...props }) => {
    const first_row = data[0];
    const rows = data.map((row, index) => <TableRow>
        <TableCell>{index}</TableCell>
        {row.map(elem => <TableCell>{elem}</TableCell>)}
    </TableRow>)
    console.log(rows);
    return (
        <div style={{ width: 500 }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {first_row.map((elem, index) => <TableCell>{index}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default MuiDataTable;